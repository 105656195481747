import jsPDF from "jspdf";
import "jspdf-autotable";
import { Table, Button, Card } from "react-bootstrap";
// import "./App.css";
// import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { fetchDailyReports } from "./redux/stateSlice";
// import { auth, provider, signInWithRedirect } from "./firebase";

const DailyReport = () => {
  let reports = [{}];
  const navigate = useNavigate();
  useEffect(() => {
    console.log("reports______", reports);
  }, [reports]);
  // return null;
  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text(`Daily Report of ${new Date().toLocaleDateString()}`, 14, 10);
    const tableColumn = ["User", "Check In", "Check Out"];
    const tableRows = reports.map((report) => [
      "User",
      "DD/MM/YYYY",
      "DD/MM/YYYY",
    ]);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [0, 123, 255] },
    });
    doc.save("Daily_Report.pdf");
  };

  return (
    <div className="container mt-4">
      <Card className="shadow-sm">
        <Card.Header className="bg-primary text-white text-center">
          <div className="d-flex justify-content-between align-items-center">
            <Button variant="light" onClick={() => navigate("/dashboard")}>
              Back
            </Button>
            <h4>
              Check In Check Out Report of {new Date().toLocaleDateString()}
            </h4>
          </div>
        </Card.Header>
      </Card>

      <div
        style={{
          maxHeight: "400px",
          overflowY: "auto",
          border: "1px solid #ccc",
        }}
      >
        <Table bordered hover responsive className="text-center mb-0">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>User</th>

              <th>Check In</th>
              <th>Check Out</th>
            </tr>
          </thead>
          <tbody>
            {reports?.map((report, index) => (
              <tr key={index}>
                <td style={{ wordBreak: "break-word" }}>{"user@gmail.com"}</td>

                <td>{"HH:MM:SS DD/MM/YYYY"}</td>
                <td> {"HH:MM:SS DD/MM/YYYY"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Button onClick={downloadPDF} className="btn-primary m-4">
        Download PDF
      </Button>
    </div>
  );
};

export default DailyReport;
