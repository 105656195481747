import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import { expiryFilters } from "../database/expiryFilters";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  fetchCompanyDetails,
  fetchUserList,
  fetchUserData,
  updateCompanyReportFields,
  updateUserReportFields,
} from "../database/firebaseApis";
import { setAuthenticated } from "../redux/authSlice";
import { Dropdown, Form, Tab, Tabs, useAccordionButton } from "react-bootstrap";
import filteringLogic from "../utils/filteringLogic";
import useFetchCompanies from "../hooks/useFetchComponies";
import { useSwitches } from "../hooks/useSwitches";
import { displayCompanyDetails } from "../database/companyInfo";
import { userDetailsInfo } from "../database/userInfo";
import sendEmail from "../utils/sendEmail";
import PaymentInfo from "../utils/paymentFormModal";
import {
  fetchActivityLogs,
  fetchActivityLogsR,
  fetchGeoLocation,
  logActivityThunk,
} from "../redux/stateSlice";
import Sidebar from "../utils/Navbar";
import BasicExample from "../utils/Navbar";

const { doc, updateDoc, getDoc } = require("firebase/firestore");
const firestore = require("../database/firebase");

export default function Dashboard() {
  const [companyFilters, setCompanyFilters] = React.useState("allList");
  const [expirySpanState, setExpirySpanState] = React.useState("0");
  const filterNames = {
    0: "Already Expired",
    30: "30 Days Remaining",
    60: "60 Days Remaining",
    45: "45 Days Remaining",
  };
  const myIp = useSelector((state) => state.state.ip);
  // const activityLog = useSelector((state) => state.state.activityLog);
  const admin = useSelector((state) => state.state.admin);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [FilteredUserList, setFilteredUserList] = React.useState(null);
  const [companyDetailsState, setCompanyDetailsState] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [searchUserText, setSearchUserText] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedUSer, setSelectedUser] = React.useState("");
  const [resetTimerOn, setResetTimerOn] = React.useState(false);
  const [verifyTimerOn, setVerifyTimerOn] = React.useState(false);
  const [secretTimerOn, setSecretTimerOn] = React.useState(false);
  const [filteredCompanyList, setFilteredCompanyList] = React.useState([]);
  const reportFieldState = useSelector(
    (state) => state.state.companyReportFields
  );
  const { companyList } = useFetchCompanies(); // Use the custom hook

  const { activityLog, status, error } = useSelector((state) => state.state); // Adjust according to your slice name

  React.useEffect(() => {
    dispatch(fetchGeoLocation());
    dispatch(fetchActivityLogs());
  }, []);
  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  React.useEffect(() => {
    console.log("hii I am admin", admin);
  }, [admin]);

  React.useEffect(() => {
    const filteredCompany = companyList.filter(
      (company) =>
        filteringLogic(company, companyFilters, expirySpanState) &&
        (searchText === "" ||
          company.id?.toLowerCase().includes(searchText.toLowerCase())) // Apply search only if text is not empty
    );
    setFilteredCompanyList(filteredCompany);
  }, [companyFilters, expirySpanState, companyList, searchText]);

  React.useEffect(() => {
    fetchCompanyDetailsFunc(selectedCompany);
    fetchUsers(selectedCompany);
  }, [selectedCompany, companyDetailsState]);

  React.useEffect(() => {
    const filteredUserList = userList.filter(
      (user) =>
        searchUserText === "" ||
        user.id?.toLowerCase().includes(searchUserText.toLowerCase())
    );

    setFilteredUserList(filteredUserList);
  }, [searchUserText, userList]);
  React.useEffect(() => {
    fetchUserDetails(selectedCompany, selectedUSer);
  }, [selectedUSer, selectedCompany, userDetails]);

  const fetchCompanyDetailsFunc = async (companyId) => {
    try {
      const companyDetailsData = await fetchCompanyDetails(companyId);

      setCompanyDetailsState(companyDetailsData);
    } catch (error) {
      console.error("Error fetching company details", error);
    }
  };

  const fetchUsers = async (companyId) => {
    try {
      const users = await fetchUserList(companyId);
      setUserList(users);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const fetchUserDetails = async (companyId, userId) => {
    try {
      const userDetails = await fetchUserData(companyId, userId);
      setUserDetails(userDetails);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleReset = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }

      const userDocRef = doc(
        firestore,
        "Company",
        userDetails.companyId,
        "users",
        userDetails.id
      );
      // console.log("User Document Reference:", userDocRef);

      await updateDoc(userDocRef, {
        deviceId: "",
      });

      const tt = new Date().toLocaleString(); // Format the current time
      const logStatement = `${admin.email.toLowerCase()} Device Data cleared for ${selectedUSer} from ${myIp}  at ${tt}`;

      dispatch(logActivityThunk(logStatement));
      // Update the local state to reflect the changes
      setUserDetails((prevUserData) => ({
        ...prevUserData,
        deviceId: "",
      }));

      // Set timerOn to true
      setResetTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setResetTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // console.log("device data is cleared on ui");
    } catch (error) {
      console.error("Error updating device data in Firebase:", error);
    }
  };

  const handleVerify = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }
      console.log("we are user details", userDetails);
      // Send a POST request to the verification email API endpoint
      const response = await fetch(
        "https://us-central1-stemm-trak.cloudfunctions.net/app/api/sendverifiedEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userDetails.Email, // Assuming userDetails.Email contains the user's email
            name: userDetails.Name, // Assuming userDetails.Name contains the user's name
          }),
        }
      );

      const tt = new Date().toLocaleString(); // Format the current time
      const logStatement = `${admin.email.toLowerCase()} Verified Email for ${selectedUSer} from ${myIp}  at ${tt}`;

      dispatch(logActivityThunk(logStatement));

      // Set timerOn to true
      setVerifyTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setVerifyTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // Handle the response if needed
      console.log("Verification email sent successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const secretKeyHandler = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }
      const tt = new Date().toLocaleString(); // Format the current time
      const logStatement = `${admin.email.toLowerCase()} Updated Secret Key for ${selectedUSer} from ${myIp}  at ${tt}`;

      dispatch(logActivityThunk(logStatement));
      setSecretTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setSecretTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds

      // Handle the response if needed
      // console.log("secret keyyyy successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const handleLogout = async () => {
    setSelectedCompany("");
    setSelectedUser("");
    setUserDetails(null);
    dispatch(setAuthenticated(false));

    const tt = new Date().toLocaleString(); // Format the current time
    const logStatement = `${admin.email.toLowerCase()} logged out from IP : ${myIp}  at ${tt}`;

    dispatch(logActivityThunk(logStatement));
    localStorage.setItem("adminUser", null);
    localStorage.setItem("isAuthenticated", false);
  };

  const handleActivityLogs = () => {
    navigate("/dashboard/activityLogs");
  };

  return (
    <Container className="bg-light border border rounded-lg shadow-lg">
      <BasicExample
        handleLogout={handleLogout}
        handleActivityLogs={handleActivityLogs}
      />

      <Row className="py-3 bg-secondary align-items-center">
        <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="expiry-dropdown">
              {`Expiry Filters : ${filterNames[expirySpanState]} `}
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-1">
              {["0", "30", "45", "60"].map((filter) => (
                <Row className="align-items-center mb-2" key={filter}>
                  <Col xs="auto" className="pr-2">
                    <Form.Check
                      onChange={() => setExpirySpanState(filter)}
                      checked={expirySpanState === filter}
                      type="checkbox"
                      id={`expiry-${filter}`}
                    />
                  </Col>
                  <Col>
                    <span className="filter-name">{filterNames[filter]}</span>
                  </Col>
                </Row>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs={12} sm={6} md={4} className="mb-2 mb-md-0">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="plan-type-dropdown">
              {`Plan Type Filters : ${companyFilters} `}
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-1">
              {expiryFilters.map((filter) => (
                <Row className="align-items-center mb-2" key={filter.key}>
                  <Col xs="auto" className="pr-2">
                    <Form.Check
                      onChange={() => setCompanyFilters(filter.key)}
                      checked={filter.key === companyFilters}
                      type="checkbox"
                      id={`plan-${filter.key}`}
                    />
                  </Col>
                  <Col>
                    <span className="filter-name">{filter.name}</span>
                  </Col>
                </Row>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs={12} md={2} className="mb-2">
          <PaymentInfo />
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs={12} sm={12} md={12} lg="4">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <input
                      type="text"
                      placeholder="Search Company"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        marginLeft: "5px",
                        flex: 1,
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredCompanyList.length > 0 &&
                  filteredCompanyList.map((company) => (
                    <TableRow
                      key={company.id}
                      // sx={{ "&:last-child td, &:last-child th": { border: 2 } }}
                      sx={{
                        border: selectedCompany === company.id ? 2 : 0,
                        background:
                          selectedCompany === company.id ? "#E5E4E2" : "",
                      }}
                    >
                      <TableCell
                        onClick={() => {
                          setSelectedCompany(company.id);
                          setSelectedUser("");
                          setUserDetails("");
                        }}
                        component="th"
                        scope="row"
                        sx={{ cursor: "pointer" }}
                      >
                        <Row>
                          <Col className="d-flex align-items-center justify-content-between">
                            <h6 className="text-center">{company.id}</h6>
                          </Col>

                          <Col>
                            {/* <Button onClick={() => sendEmail(company.id)}> */}
                            <Button
                              // disabled={userDetails.RoleID === "User"}
                              onClick={() => {
                                const tt = new Date().toLocaleString(); // Format the current time
                                const logStatement = `${admin.email.toLowerCase()} Triggered mail to ${
                                  company.id
                                } from ${myIp}  at ${tt}`;

                                dispatch(logActivityThunk(logStatement));
                                sendEmail(
                                  company.id,
                                  expirySpanState,
                                  companyFilters
                                );
                              }}
                            >
                              Trigger Mail
                            </Button>
                          </Col>
                        </Row>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        {selectedCompany && (
          <Col xs={12} sm={12} md={12} lg="3">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Row>
                        <Col className="d-flex align-items-center">
                          <input
                            type="text"
                            placeholder="Search User"
                            value={searchUserText}
                            onChange={(e) => setSearchUserText(e.target.value)}
                            style={{
                              border: "none",
                              outline: "none",
                              marginLeft: "5px",
                              flex: 1,
                            }}
                          />
                        </Col>
                      </Row>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "500px",
                    overflowY: "auto",

                    display: "block", // Ensures the TableBody behaves like a block-level element
                  }}
                >
                  {FilteredUserList?.map((user) => (
                    <TableRow
                      key={user?.id}
                      sx={{
                        border: selectedUSer === user?.id ? 2 : 0,
                        background: selectedUSer === user.id ? "#E5E4E2" : "",
                      }}
                    >
                      <TableCell
                        onClick={() => setSelectedUser(user?.id)}
                        component="th"
                        scope="row"
                        sx={{ cursor: "pointer" }}
                      >
                        {`${user.id}, ${user.Email}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        )}
        <Col xs={12} sm={12} md={12} lg="5">
          {" "}
          <Tabs
            defaultActiveKey="companyInfo"
            id="fill-tab-example"
            className="mb-3"
            fill
          >
            {selectedCompany && (
              <Tab eventKey="companyInfo" title="CompanyInfo">
                <h6>Attendance Reports</h6>
                <Row className="pb-4">
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Daily</div>
                      <Switch
                        checked={companyDetailsState?.dailyReportPdf}
                        onChange={() => {
                          updateCompanyReportFields(selectedCompany, {
                            dailyReportPdf:
                              !companyDetailsState?.dailyReportPdf,
                          });
                          const tt = new Date().toLocaleString(); // Format the current time
                          const logStatement = `${admin.email.toLowerCase()} Updated Daily report field for ${selectedCompany} to ${!companyDetailsState?.dailyReportPdf} from ${myIp}  at ${tt}`;

                          dispatch(logActivityThunk(logStatement));
                        }}
                      />
                    </div>{" "}
                  </Col>{" "}
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Weekly</div>
                      <Switch
                        checked={companyDetailsState?.weeklyReportPdf}
                        onChange={() =>
                          updateCompanyReportFields(selectedCompany, {
                            weeklyReportPdf:
                              !companyDetailsState?.weeklyReportPdf,
                          })
                        }
                      />
                    </div>{" "}
                  </Col>{" "}
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Monthly</div>
                      <Switch
                        checked={companyDetailsState?.monthlyReportPdf}
                        onChange={() =>
                          updateCompanyReportFields(selectedCompany, {
                            monthlyReportPdf:
                              !companyDetailsState?.monthlyReportPdf,
                          })
                        }
                      />
                    </div>{" "}
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center">
                      <div>Face BioMetrics</div>
                      <Switch
                        checked={companyDetailsState?.faceBiometric}
                        onChange={() =>
                          updateCompanyReportFields(selectedCompany, {
                            faceBiometric: !companyDetailsState?.faceBiometric,
                          })
                        }
                      />
                    </div>{" "}
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center">
                      <div>Auto Clock Out</div>
                      <Switch
                        checked={companyDetailsState?.enableAutoClockOut}
                        onChange={() =>
                          updateCompanyReportFields(selectedCompany, {
                            enableAutoClockOut:
                              !companyDetailsState?.enableAutoClockOut,
                          })
                        }
                      />
                    </div>{" "}
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center">
                      <div>Enable PDF Creation</div>
                      <Switch
                        checked={companyDetailsState?.enablePdfCreation}
                        onChange={() =>
                          updateCompanyReportFields(selectedCompany, {
                            enablePdfCreation:
                              !companyDetailsState?.enablePdfCreation,
                          })
                        }
                      />
                    </div>{" "}
                  </Col>
                </Row>
                {true && (
                  <Row>
                    <Col>
                      <div style={{ height: "300px", overflowY: "scroll" }}>
                        {displayCompanyDetails(companyDetailsState)}
                      </div>
                    </Col>
                  </Row>
                )}
              </Tab>
            )}
            {userDetails && (
              <Tab eventKey="paymentInfo" title="Payment Info">
                {`Payment ID : ${
                  companyDetailsState?.featuresPayment &&
                  companyDetailsState?.featuresPayment[0]?.paymentId
                }`}
              </Tab>
            )}
            {userDetails && (
              <Tab eventKey="userInfo" title="User Info">
                <Row>
                  {" "}
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Mobile Access</div>
                      <Switch
                        checked={userDetails.mobileAccess}
                        onChange={() =>
                          updateUserReportFields(
                            selectedCompany,
                            userDetails.id,
                            {
                              mobileAccess: !userDetails.mobileAccess,
                            }
                          )
                        }
                      />
                    </div>{" "}
                  </Col>{" "}
                  <Col>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>web Access</div>
                      <Switch
                        checked={userDetails.webAccess}
                        onChange={() =>
                          updateUserReportFields(
                            selectedCompany,
                            userDetails.id,
                            {
                              webAccess: !userDetails.webAccess,
                            }
                          )
                        }
                      />
                    </div>{" "}
                  </Col>{" "}
                </Row>

                <Row>
                  {userDetails && (
                    <div style={{ height: "300px", overflowY: "scroll" }}>
                      {userDetailsInfo.map((detail) => (
                        <p key={detail.value}>
                          {`${detail.label}: ${
                            detail.value === "JoiningDate" ||
                            detail.value === "lastLoginTime" ? (
                              new Date(
                                userDetails[detail.value] * 1000
                              ).toLocaleDateString()
                            ) : detail.value === "imageUrl" ? (
                              <img
                                style={{ height: "100px" }}
                                src={userDetails[detail.value]}
                                alt="User"
                              />
                            ) : (
                              userDetails[detail.value] || "N/A"
                            )
                          }`}
                        </p>
                      ))}
                    </div>
                  )}
                </Row>
                {selectedUSer && (
                  <Row className="mt-4">
                    {" "}
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      <Button
                        onClick={handleVerify}
                        variant="contained"
                        disabled={verifyTimerOn}
                      >
                        Verify Email
                      </Button>
                      {/* <Button
                        onClick={secretKeyHandler}
                        variant="contained"
                        disabled={secretTimerOn}
                        href="#contained-buttons"
                      >
                        Create / Update Secret Key
                      </Button> */}
                      <Button
                        onClick={handleReset}
                        disabled={resetTimerOn}
                        variant="contained"
                        href="#contained-buttons"
                      >
                        Reset
                      </Button>
                    </Stack>
                    {secretTimerOn ? (
                      <p className="p-1">For Secret Key Wait for two minuts</p>
                    ) : (
                      ""
                    )}
                    {resetTimerOn ? (
                      <p className="p-1">For Reset Key Wait for two minuts</p>
                    ) : (
                      ""
                    )}
                    {verifyTimerOn ? (
                      <p className="p-1">For Verify Key Wait for two minuts</p>
                    ) : (
                      ""
                    )}
                  </Row>
                )}
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}
